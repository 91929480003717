window.onload = function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#013F4D"
            },
            "button": {
                "background": "#cbdb2e"
            }
        },
        "content": {
            "href": "/cookie-policy/"
        }
    });
}