$(function () {

    if ($('.flexible-content-section .map-block').length < 1) {
        return;
    }

    // Align map blocks
    $('body').align({
        selector: '.map-block .map-block__section .address .text'
    });

    // Generate Google Maps
    $(function () {
        $('[data-google-map]').each(function (boxIndex, boxElement) {
            var box = $(boxElement);
            var data = box.data('google-map');

            if (typeof data === 'string') {
                data = JSON.parse(data);
            }

            box.googleMaps(data);
        });
    });

});