$(function () {

    var ctaCard = $('.cta-card');

    if (ctaCard.length < 1) {
        return;
    }

    $('.cta-card-section__column-wrapper').align({
        selector: '.cta-card .title, .cta-card .cta-card__body, .cta-card .cta-card__body > p',
    });

    // Align the card titles along the bottom edge.
    // Use 'body' instead of '.cta-card-section__column-wrapper' or it won't work
    $('body').align({
        selector: '.cta-card .title',
        bottom: true,
    });

});