$(function () {

    var bannerButton = $('.banner .banner__arrow');

    if (bannerButton.length < 1) {
        return;
    }

    bannerButton.on('click', function () {
        var parentElement = $(this).closest('.banner');
        var parentNextElement = parentElement.next();

        if (typeof parentNextElement !== 'undefined') {
            $('html, body').animate({
                scrollTop: parentNextElement.offset().top,
            });
        }
    });

});