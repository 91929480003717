$(function () {

    var caseStudyCard = $('.case-study-card');

    if (caseStudyCard.length < 1) {
        return;
    }

    $('.case-study-section__column-wrapper').align({
        selector: '.case-study-card .title, .case-study-card .case-study-card__body, .case-study-card .case-study-card__body > p'
    });

});