$(function () {

    if ($('.site-footer__company-locations').length < 1) {
        return;
    }

    $('.site-footer__company-locations .row').align({
        selector: '.location .location__address .text'
    });

});