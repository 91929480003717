$(function () {

    subnav = $('.navigation--has-subnav-toggle');

    if (subnav.length < 1) {
        return;
    }

    // Number of allowed subnav items
    var allowedSubnavItems = 5;

    if ($(window).width() >= 768) {
        allowedSubnavItems = 8;
    }

    // Append subnav toggle button only if there are
    // more than 8 subnav items
    if (subnav.find('li').length > allowedSubnavItems) {
        subnav.append('<button id="sidebar-subnav-toggle" class="button button--blue"><span class="text">Show more services</span></button>');
    } else {
        // There's nothing else to do so stop running the script.
        return;
    }

    // Subnav toggle icons
    var plusIcon = '<svg id="plus-icon" class="icon icon--active" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>';

    var minusIcon = '<svg id="minus-icon" class="icon icon--hidden" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>';

    // Append icons to button
    var subnavToggle = $('#sidebar-subnav-toggle');

    subnavToggle.append(plusIcon);
    subnavToggle.append(minusIcon);

    // Hide subnav items
    var subnavMenu = subnav.find('ul');

    subnavMenu.find('li').each(function () {
        // Get index value of list item
        var indexValue = $(this).index();

        // If the current list item is the 8th or higher
        // then hide it.
        if (indexValue >= allowedSubnavItems) {
            // If the current item is one that represents
            // the current page then show it.
            // Also force all the ones following it to
            // and all the ones before it to show.
            if ($(this).hasClass('current-menu-item')) {
                $(this).prevAll('li').addClass('current-menu-item-sibling');
                $(this).nextAll('li').addClass('current-menu-item-sibling');

                // Remove the subnav toggle button as it's
                // no longer required.
                subnavToggle.remove();
            } else {
                $(this).addClass('hidden');
            }
        }
    });

    // Store the click status of the toggle button.
    // This will be required to change the text of the
    // button.
    var clicked = false;

    // Hide/show functionality
    subnavToggle.on('click', function () {
        // Toggle button and icon classes
        $(this).find('.icon').toggleClass('icon--hidden').toggleClass('icon--active');
        $(this).toggleClass('active');

        // Change button text
        if (clicked === false) {
            clicked = true;
            $(this).find('.text').text($(this).text().replace('more', 'less'));
        } else {
            clicked = false;
            $(this).find('.text').text($(this).text().replace('less', 'more'));
        }

        // Show/hide menu
        var listItems = subnavMenu.find('li');

        listItems.each(function () {
            var indexValue = $(this).index();

            if (indexValue >= allowedSubnavItems) {
                $(this).toggleClass('hidden');
            }
        });
    });

});