$(function () {
    /** Append main menu toggle button */
    $('.site-header__bottom').prepend('<button class="nav-toggle"><img src="/content/images/icons/menu-toggle.png" /></button>');

    /** Append subnav toggle button */
    $('.site-header .menu-item-has-children > a').append('<button class="submenu-toggle"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path d="M7,10l5,7.7l5-7.7H7z"/></svg></button>');

    /** Append icons to submenu links */
    $('.sub-menu li a').append('<svg xmlns="http://www.w3.org/2000/svg" width="8.37mm" height="7.52mm" viewBox="0 0 23.73 21.31"><polygon points="23.58 10.29 2.83 10.59 0.13 21.31 23.58 10.29" fill="#015c70"/><polygon points="23.73 10.41 2.99 10.65 0 0 23.73 10.41" fill="#0ba6a7"/></svg>');

    /** Declare variables */
    var header = $('.site-header');
    var nav = $('.site-navigation');
    var navToggle = $('.site-header .nav-toggle');
    var subMenu = $('.sub-menu');

    /** Add/remove classes when JS is enabled */
    nav.addClass('site-navigation--hidden');
    nav.removeClass('site-navigation--no-js');
    subMenu.addClass('sub-menu--hidden');

    /** Nav toggle functionality */
    navToggle.on('click', function () {
        $(this).toggleClass('nav-toggle--active');
        nav.toggleClass('site-navigation--hidden');
    });


    /** Subnav toggle functionality */
    $('.submenu-toggle').each(function (e) {

        $(this).on('click', function (e) {

            e.stopPropagation();
            e.preventDefault();

            var submenu = $(this).parent('a').next('.sub-menu');
            var parentAnchorTag = $(this).parent('a');

            /**
             * Remove active states from all menu toggle buttons except the current one
             */
            $(this).toggleClass('submenu-toggle--active');
            $('.submenu-toggle').not($(this)).removeClass('submenu-toggle--active');

            /**
             * Hide all submenus except for the current one
             */
            $('.sub-menu').not(submenu).removeClass('sub-menu--active');
            $('.sub-menu').not(submenu).addClass('sub-menu--hidden');
            $('.menu-item-has-children > a').not(parentAnchorTag).removeClass('current-menu-item');

            /**
             * Toggle the active and hidden classes of the current submenu
             */
            submenu.toggleClass('sub-menu--hidden');
            submenu.toggleClass('sub-menu--active');
            parentAnchorTag.toggleClass('current-menu-item');

            if (submenu.hasClass('sub-menu--active')) {
                $('body').addClass('nav-active');
            } else {
                $('body').removeClass('nav-active');
            }

        });

        /**
         * Remove active states when user clicks anywhere else on the page
         */
        $('html body').on('click', function () {
            $('.submenu-toggle').removeClass('submenu-toggle--active');
            $('.sub-menu').removeClass('sub-menu--active');
            $('.sub-menu').addClass('sub-menu--hidden');
            $('.menu-item-has-children > a').removeClass('current-menu-item');
            $('body').removeClass('nav-active');
        });
    });
});