$(function () {

    // Image slider exists?
    if ($('.flexible-content-section .image-slider').length < 1) {
        return;
    }

    /**
     * Missing forEach on NodeList for IE11.
     * 
     * See: https://github.com/miguelcobain/ember-paper/issues/1058
     */
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    // Remove classes
    $('.flexible-content-section .image-slider .image-slider__slide-wrap').removeClass('image-slider__slide-wrap--no-js');
    $('.flexible-content-section .image-slider .image-slider__controls-wrap').removeClass('image-slider__controls-wrap--no-js');

    // Append controls
    var controlPrev = '<button class="control control--prev"><?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg></button>';
    var controlNext = '<button class="control control--next"><?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg></button>';
    $('.flexible-content-section .image-slider .image-slider__controls-wrap').append(controlPrev);
    $('.flexible-content-section .image-slider .image-slider__controls-wrap').append(controlNext);

    /**
     * Some pages may have more than one image slider.
     * The following is required to make the slider
     * component work on regardless of how many are
     * on the page.
     * 
     * Refer to: https://github.com/ganlanyuan/tiny-slider/issues/653
     */
    const flexContentSliders = document.querySelectorAll('.flexible-content-section .image-slider');

    flexContentSliders.forEach(function (slider) {

        // Initialise slider/carousel
        const flexContentImageSlider = tns({
            container: slider.querySelector('.image-slider__slide-wrap'),
            items: 1,
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: 7000,
            speed: 1000,
            loop: true,
            nav: true,
            navPosition: 'bottom',
            navContainer: slider.querySelector('.image-slider__controls-wrap .pager-wrap'),
            mouseDrag: false,
            arrowKeys: false,
            touch: true,
            controls: true,
            nextButton: slider.querySelector('.image-slider__controls-wrap .control--next'),
            prevButton: slider.querySelector('.image-slider__controls-wrap .control--prev'),
            preventActionWhenRunning: true,
        });
    });

});